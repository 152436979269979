import * as React from "react"
import PropTypes from "prop-types"
import "../../styles/components/field--faq-section.scss"

const FieldFaqSection = ({ children, title }) => (
  <div className="field field--faq-section">
    <h3 className="field field--title">{title}</h3>
    {children}
  </div>
)

FieldFaqSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default FieldFaqSection
