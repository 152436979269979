import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/regions/header"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import FieldFaqSection from "../components/field/fieldFaqSection"
import FieldAccordion from "../components/field/fieldAccordion"
import Accordion from "../components/item/accordion"
import "../styles/components/block--page-top-content--faq.scss"
import "../styles/components/block--faq.scss"

const FAQPage = () => (
  <>
    <Header />
    <Layout>
      <Seo
        title="Frequently Asked Questions"
        description="You have got questions? We've got answers! Learn more about our best-in-class workflows, APIs, email blueprints, and more."
        pageClass="page--faq"
      />

      <Container classes="block--page-top-content block--page-top-content--faq">
        <FieldText>
          <h3 className="text text--centered text--red">FAQ</h3>
          <h1 className="text text--centered">Frequently Asked Questions</h1>
          <p className="text text--centered">
            You have got questions? We've got answers! Learn more about our
            best-in-class workflows, APIs, email blueprints, and more.
          </p>
        </FieldText>
      </Container>

      <Container classes="block--faq">
        <FieldFaqSection title="Getting Started">
          <FieldAccordion>
            <Accordion title="What happens when i start a free trial?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Adipisci aliquam amet animi at blanditiis consequatur doloribus
                eligendi facilis illo illum magni molestias mollitia nam natus
                necessitatibus, nobis numquam officia officiis quaerat quos
                sunt, tempore totam vitae, voluptate voluptatem voluptatum.
              </p>
            </Accordion>
            <Accordion title="What's the setup process like?">
              <p>
                Consequuntur corporis dignissimos, dolorum eligendi expedita
                nulla placeat qui repellat? Earum, est?
              </p>
            </Accordion>
            <Accordion title="Are there migration services available?">
              <p>
                Ad aut cupiditate dolorum hic iste labore perspiciatis porro
                quam reiciendis sit!
              </p>
            </Accordion>
            <Accordion title="What type of support is offered?">
              <p>
                Beatae dolor eligendi est illum libero obcaecati provident
                repellat temporibus voluptas voluptates!
              </p>
            </Accordion>
          </FieldAccordion>
        </FieldFaqSection>
        <FieldFaqSection title="Branding & Customization">
          <FieldAccordion>
            <Accordion title="What happens when i start a free trial?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Consequatur doloremque quam quod.
              </p>
            </Accordion>
            <Accordion title="What's the setup process like?">
              <p>
                Accusantium adipisci dicta distinctio enim incidunt iusto
                molestias officia vero, voluptas voluptates!
              </p>
            </Accordion>
            <Accordion title="Are there migration services available?">
              <p>
                Accusamus aliquam dolorem ex explicabo facilis illo inventore
                nobis quas reiciendis voluptatibus.
              </p>
            </Accordion>
            <Accordion title="What type of support is offered?">
              <p>
                Commodi deleniti distinctio dolorum earum eveniet expedita
                incidunt ipsum optio praesentium, sit.
              </p>
            </Accordion>
          </FieldAccordion>
        </FieldFaqSection>
        <FieldFaqSection title="Billing">
          <FieldAccordion>
            <Accordion title="What happens when i start a free trial?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. In,
                nostrum, porro. Velit.
              </p>
            </Accordion>
            <Accordion title="What's the setup process like?">
              <p>
                Adipisci asperiores aspernatur corporis explicabo facilis nihil,
                non perspiciatis quasi reiciendis sed.
              </p>
            </Accordion>
            <Accordion title="Are there migration services available?">
              <p>
                At id incidunt pariatur quia, reprehenderit soluta vitae.
                Debitis facilis laboriosam voluptas!
              </p>
            </Accordion>
            <Accordion title="What type of support is offered?">
              <p>
                Asperiores consequuntur facere hic modi tempora. Debitis dolor
                eius maxime quos voluptates!
              </p>
            </Accordion>
          </FieldAccordion>
        </FieldFaqSection>
        <FieldFaqSection title="List Management">
          <FieldAccordion>
            <Accordion title="What happens when i start a free trial?">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias
                culpa illum quod.
              </p>
            </Accordion>
            <Accordion title="What's the setup process like?">
              <p>
                Dicta distinctio dolore facere maxime nobis optio velit.
                Asperiores eaque maxime neque?
              </p>
            </Accordion>
            <Accordion title="Are there migration services available?">
              <p>
                Architecto in inventore neque quibusdam? Eaque expedita
                molestiae odio possimus unde voluptatibus.
              </p>
            </Accordion>
            <Accordion title="What type of support is offered?">
              <p>
                Ad aut cumque et exercitationem explicabo facere fugiat
                laudantium libero maiores, quo?
              </p>
            </Accordion>
          </FieldAccordion>
        </FieldFaqSection>
      </Container>
    </Layout>
  </>
)

export default FAQPage
